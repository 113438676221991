import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//
import TemaProvider from './context/Tema';
//
import AllRoutes from './routes';

function App() {
  return (
    <TemaProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        draggable
        theme="colored"
      />
      <AllRoutes />
    </TemaProvider>
  );
}


export default App;
