import { createContext, ReactNode } from 'react';
//
import { ThemeProvider, createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

export const TemaContext = createContext({});



export default function TemaProvider({ children }: { children: ReactNode }) {

    const isDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;

    const theme = isDarkTheme
        ? createTheme({
            palette: {
                mode: 'dark',
                primary: { main: '#2196f3' },
                secondary: { main: '#F2B950' },
                background: {
                    default: '#303030',
                    paper: '#424242',
                }
            },
        })
        : createTheme({
            palette: {
                mode: 'light',
                primary: { main: '#07418a' },
                secondary: { main: '#F2B950' },
            },
        });

    return (
        <TemaContext.Provider
            value={{}}
        >
            <ThemeProvider theme={theme}>
                <CssBaseline enableColorScheme />
                {children}
            </ThemeProvider>
        </TemaContext.Provider>
    );
}