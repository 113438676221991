import { useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
//@ts-ignore
import { mask } from 'remask';
//
import { decodeBase64 } from '../../functions/crypt';
//
import apiPlay from '../../services/apiPlay';
//
import {
  Typography,
  Container,
  TextField,
  Skeleton,
  Divider,
  Paper,
  Box,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

export default function Portabilidade() {

  const [searchParams] = useSearchParams();

  const [detalhesLinha, setDetalhesLinha] = useState<{ [key: string]: any } | null>(null);
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isSolicita, setIsSolicita] = useState(true);
  const [concluido, setConcluido] = useState(false);
  const [isPf, setIsPf] = useState(true);
  const [cod, setCod] = useState('');
  const [cpf, setCpf] = useState('');

  const msisdn = searchParams.get('m') ? decodeBase64(searchParams.get('m') || '') : '';

  function verificaCpf() {

    setLoadingBtn(true);

    let dados = {
      msisdn: msisdn,
      pmsisdn: detalhesLinha?.tempmsisdn,
      cpf: cpf,
    }

    apiPlay.post('/api/portabilidade/portincpf', dados)
      .then(response => {
        toast.success('Dados confirmados.');
        setLoadingBtn(false);
        setIsSolicita(false);

      }).catch(error => {
        console.log('portincpf', error);
        toast.error('Erro ao confirmar dados');
        setLoadingBtn(false);
      })
  }

  function confirmaPortabilidade() {

    setLoadingBtn(true);

    let dados = {
      pmsisdn: detalhesLinha?.tempmsisdn,
      token: cod,
    }

    apiPlay.post('/api/portabilidade/portintoken', dados)
      .then(response => {
        setCod('');
        setConcluido(true);
        setLoadingBtn(false);

      }).catch(error => {
        console.log('portintoken', error);
        toast.error('Erro ao confirmar portabilidade');
        setLoadingBtn(false);
      })
  }

  useEffect(() => {
    if (!msisdn) return;

    setLoadingPage(true);

    let dadosApiVer = {
      msisdn: msisdn,
    }

    apiPlay.post('/api/portabilidade/verificacpf', dadosApiVer)
      .then((response) => {
        setDetalhesLinha(response.data);
        setIsPf(response.data?.cpf.length === 11);
        response.data.portability === 1 && setConcluido(true);
        setLoadingPage(false);

      }).catch((error) => {
        console.log(error);//LOG
        toast.error('Erro ao buscar detalhes da solicitação');

      })

  }, [])

  if (!msisdn) return <h3>Not Found 404</h3>;

  if (loadingPage) {
    return (
      <Container
        component="section"
        maxWidth="xs"
        sx={{ mt: 25 }}
      >
        <Skeleton variant="rounded" width={'100%'} height={340} />
      </Container>
    )
  }

  if (concluido) {
    return (
      <Container
        component="section"
        maxWidth="xs"
        sx={{ mt: 25 }}
      >
        <Typography sx={{ mb: 2 }} variant='h3' textAlign='center'>
          Parabéns!
        </Typography>
        <Typography variant='h5' textAlign='center'>
          Seu pedido de portabilidade está confirmado!
        </Typography>
      </Container>
    )
  }

  return (
    <Container
      component="section"
      maxWidth="xs"
      sx={{ mt: 25 }}
    >
      <Paper sx={{ width: '100%', p: 3 }} elevation={2}>
        <Typography
          variant='h5'
          fontWeight={600}
          textAlign='center'
        >
          {isSolicita
            ? 'Solicitação de Portabilidade'
            : 'Confirmação de Portabilidade'
          }
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Typography
          variant='subtitle1'
          textAlign='center'
        >
          {isSolicita
            ? 'Confirme os dados a baixo para com prosseguir com a portabilidade:'
            : 'Digite o número de Token enviado para o número descrito abaixo:'
          }
        </Typography>

        <Typography
          variant='subtitle1'
          textAlign='center'
        >
          {isSolicita
            ? (isPf ? `XXX.XXX.${detalhesLinha?.cpf.slice(6, 9)}-XX` : `XX.XXX.${detalhesLinha?.cpf.slice(5, 8)}/XXXX-XX`)
            : (detalhesLinha?.tempmsisdn ? mask(detalhesLinha.tempmsisdn.slice(2), ['(99) 99999-9999']) : '-')
          }
        </Typography>

        <Box
          component="form"
          onSubmit={e => { e.preventDefault(); isSolicita ? verificaCpf() : confirmaPortabilidade() }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            mt: 1,
          }}
        >
          {isSolicita
            ?
            <TextField
              type='tel'
              id='id_cpf'
              label={isPf ? 'CPF' : 'CNPJ'}
              placeholder={isPf ? '000.000.000-00' : '00.000.000/0000-00'}
              value={isPf ? mask(cpf, ['999.999.999-99']) : mask(cpf, ['99.999.999/9999-99'])}
              onChange={e => setCpf(isPf ? mask(e.target.value, ['99999999999']) : mask(e.target.value, ['99999999999999']))}
              variant='filled'
              fullWidth
              required
            />
            :
            <TextField
              type='tel'
              id='id_codigo'
              label='Código de confirmação'
              placeholder='000000'
              value={cod}
              onChange={e => setCod(e.target.value.replace(/\D/g, ''))}
              fullWidth
              autoFocus
              required
              inputProps={{ minLength: 6, maxLength: 6 }}
            />
          }

          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            size='large'
            sx={{ mt: 3, mb: 2 }}
            loading={loadingBtn}
          >
            {isPf ? 'Prosseguir' : 'Confirmar'}
          </LoadingButton>
        </Box>

      </Paper>
    </Container>
  )
}
