import { Routes, Route } from 'react-router-dom';
//
import Portabilidade from '../pages/Portabilidade';

export default function AllRoutes() {

    return (
        <Routes>
            <Route path="/" element={<Portabilidade />} />
        </Routes>
    )

}
